import React from "react"
import { Link as BaseLink } from "gatsby"
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro" //eslint-disable-line
import { Container as ContainerBase } from "../misc/Layouts.js"
import FacebookIcon from "react-feather/dist/icons/facebook"
import InstagramIcon from "react-feather/dist/icons/instagram"

const Container = tw(ContainerBase)`bg-primary-600 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-10 lg:py-12`

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LinksContainer = tw.div`mt-2 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw(BaseLink)`border-b-2 border-transparent text-primary-200 hocus:text-primary-300 hocus:border-primary-400 pb-1 transition-default duration-300 mt-2 mx-4`

const SocialLinksContainer = tw.div`mb-4`
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-primary-800 hover:text-primary-400 transition-default duration-300 mx-4`}
  svg {
    ${tw`w-8 h-8`}
  }
`

const CopyrightText = tw.p`text-center mt-2 font-medium tracking-wide text-sm text-primary-700`
// eslint-disable-next-line react/display-name
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/mintfox.clothes.atx" target="_blank">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/mintfox.clothes" target="_blank">
              <InstagramIcon />
            </SocialLink>
          </SocialLinksContainer>
          <LinksContainer>
            <Link to="/catalog">Catalog</Link>
            {/* <Link to="/sizes">Size Guide</Link> */}
            {/* <Link to="/faq">FAQ</Link> */}
            {/* <Link to="/care">Care Instructions</Link> */}
            {/* <Link to="/terms">Terms and Conditions</Link> */}
            {/* <Link to="/privacy">Privacy Policy</Link> */}
          </LinksContainer>
          <CopyrightText>
            Mint Fox Studio with ❤️ from Austin, TX. 2021.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  )
}
